import React, { useState } from 'react';
import Modal from "../../../components/Modal/Modal";
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import MaisAtacado from '../../../Services/MaisAtacado';
import { toast } from 'react-toastify';
import SomaFileUploader from '../../../components/SomaStandard/SomaFileUploader/SomaFileUploader';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';

const GerarPagamentoButton = (props) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  let hasProfile2 = Object.values(props.options).some(i => i.selected && i.id_profiles === 2)

  const handleOpen = () => {
    let periodo = [];
    let opts = props.options;

    Object.keys(opts).forEach(item => {
      if (opts[item].selected === true) {
        periodo.push(item);
      }
    });

    if (periodo.length === 0) {
      toast.error("Selecione um periodo.", { theme: "colored" });
      setFile('');
      setOpen(false);
      return;
    } else {
      setOpen(true);
    }
  };

  const handleVoltar = () => {
    let opts = props.options;
    props.setComissaoTotal(0);
    props.setCredito(0);
    props.setDeposito(0);

    Object.keys(opts).forEach(item => {
      opts[item] = {
        ...opts[item],
        selected: false
      };
    });

    props.setCheckBox(false);
  };

  const uploadFile = async () => {
    let opts = props.options;
    let periodo = [];

    Object.keys(opts).forEach(item => {
      if (opts[item].selected === true) {
        periodo.push(item);
      }
    });

    if (periodo.length === 0) {
      toast.error("Selecione um periodo.", { theme: "colored" });
      setFile('');
      setOpen(false);
      return;
    }

    setOpen(false)
    setUploading(true)
    toast.warning("Gerando Comissão!", { theme: "colored" })

    await MaisAtacado.Afiliados.minhasVendasUploadFileAdm(file, periodo).then(res => {
      setFile('');
      setOpen(false);
      setUploading(false);
      toast.success("Sucesso!", { theme: "colored" });

      props.upload(true);
      props.setComissaoTotal(0);
      props.setCredito(0);
      props.setDeposito(0);
      Object.keys(opts).forEach(item => {
        opts[item].selected = false;
      });
      props.setCheckBox(false);
    }).catch(err => {
      setFile('');
      setOpen(false);
      setUploading(false);
      setIsUploaded(false);

      if (err && err.errorId && err.errorId !== 'unhandled-error') {
        toast.error(err.message, { theme: "colored" });
      } else {
        toast.error("Erro.", { theme: "colored" });
      }
    });
  };
    
  const modalProps = {
    modalName: 'Gerar Pagamento',
    open: open,
    actionName: "ENVIAR",
    closeName: "VOLTAR",
    actionButtonDisabled: !isUploaded,
    action: uploadFile,
    onClose: ()=> {setOpen(false); setIsUploaded(false)},
    style: { width: window.innerWidth <= 768 ? "90%" : "55%"  }
  }

  const propsFileUploader = {
    setUploadStatus: setIsUploaded,
    accepts: "application/pdf",
    setFile: setFile
  }

  return (
    <div>
      <SecondaryButton onClick={handleVoltar} disabled={uploading} style={{marginRight: "10px"}}> Voltar </SecondaryButton>
      <PrimaryButton onClick={()=> hasProfile2 ? handleOpen() : uploadFile()} disabled={uploading}>Gerar Pagamento </PrimaryButton>

      <Modal {...modalProps}>
        <p style={{ color: "#8B8B8B" }}>
          Estamos quase lá! Para iniciar o processo de pagamento da(s) vendas(s) selecionada(s), é preciso 
          anexar a <b>NOTA FISCAL</b> no valor de <b><ConverteDinheiro children={props.comissaoTotal}/></b>
        </p>
        <SomaFileUploader {...propsFileUploader}/>
      </Modal>
    </div>
  );

};

export default GerarPagamentoButton;