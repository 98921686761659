import React, { useState } from 'react'

import ButtonBack from './ButtonBack.js';
import Status from '../../../../Utils/models/Status.js';
import CardBox from '../../../../components/CardBox/Card';
import Modal from '../../../../components/Modal/Modal.js';
import DivergencyTag from '../../../../components/Tag/Tag.js';
import Historic from '../../../../components/Historic/Historic.js';
import DetailsBox from '../../../../components/DetailsBox/DetailsBox.js';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton.js';
import SomaTextEditor from '../../../../components/SomaStandard/SomaTextEditor/SomaTextEditor.js';
import SomaFileUploader from '../../../../components/SomaStandard/SomaFileUploader/SomaFileUploader.js';
import useNfeCaixaFlow from '../../../../Hooks/useNfeReducer.js';

import { ReactComponent as Upload } from '../../../../assets/images/icons/upload.svg'
import { ReactComponent as SendMessage } from '../../../../assets/images/icons/enviarMensagem.svg'

import './Nfe.css'

const Acompanhamento = () => {
    const [disable, setDisable] = useState(false);
    const [textMessage, setTextMessage] = useState('');
    const [messageInfo, setMessageInfo] = useState('');
    const [modalState, setModalState] = useState('none');
    const [modalUploadInfo, setModalUploadInfo] = useState({ title: "", accepts: "" })
    const [divergenciaStatus, setDivergenciaStatus] = useState({ status: 7, produto: '', gradeItem: '', qtde: '', cor: '', tipo: '' });
    const { state, dispatch, salvarMensagem, sendFile, getEtiquetaDevolucao, download } = useNfeCaixaFlow();

    const divergencyTagContent = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {['falta'].includes("falta") && (<DivergencyTag name="Falta" color="#ED8E00" />)}
            {['sobra'].some(tag => ["extra", "sobra"].includes(tag)) && (<DivergencyTag name="Sobra" color="#2596DA" />)}
        </div>
    )

    const createAutoMessage = (msg, author, date) => {
        return {
            button: () => {
                setTextMessage(msg)
                setMessageInfo({
                    author: author,
                    date: `${new Date(date).toLocaleDateString('pt-br')}`
                })
                setModalState("showMessage");
            },
            buttonText: 'Ver mensagem'
        }
    };

    const propsCardBox = {
        title: { name: 'Embarque', id: state.data?.entrega },
        infos: [
            { left: 'Divergência', right: divergencyTagContent() },
            { left: 'Data do apontamento', right: new Date(state.data?.dataApontamento).toLocaleDateString('pt-br') },
            { left: 'Colaborador Responsável', right: state.data?.atendente },
            { left: 'Status', right: state.data?.lockedAt ? 'Finalizado' : 'Em Andamento' },
        ],
    }

    const propsHistoric = {
        title: { name: 'Histórico do Atendimento' },
        infos: state.data?.historico.map(h => {
            if (h?.valorAtual) {
                const van = JSON.parse(h.valorAnterior)
                const vat = JSON.parse(h.valorAtual)
                const prod = state.data?.tratativas.find(
                    p => p.produto.trim() === vat.produto && p.gradeItem === vat.gradeItem)
                const san = Status.find(s => s.id_status === van.status).type
                const statusAtual = Status.find(s => s.id_status === vat.status);
                const sat = statusAtual.type;
                const s = { style: { fontFamily: 'flama-semibold', color: '#3A3A40' } }
                const downloadFileType = vat.status === 17 ? 'png' : 'pdf';
                const downloadText = vat.status === 17 ? 'Baixar imagem' : 'Baixar nota fiscal';

                const buttonDownload = vat.status === 15 ? {
                    button: () => getEtiquetaDevolucao(state.data.clifor, state.data.entrega),
                    buttonText: "Gerar etiqueta de devolução"
                } : {
                    button: () => download(vat.entrega, vat.clifor, downloadFileType, vat.idArquivo),
                    buttonText: downloadText
                }

                const messageTemplate = {
                    id: h.id,
                    title: `${new Date(h.createdAt).toLocaleDateString('pt-br')} - ${h?.realUser || h?.user}`,
                    message: <>Produto {prod?.produto} em {prod?.StatusGradeItem.type?.toUpperCase()} trocou
                        de <b {...s}>{san}</b> para <b {...s}>{sat}</b></>
                }

                const button = statusAtual.autoMessage ? createAutoMessage(statusAtual.autoMessage, state.data?.atendente, h.createdAt) : buttonDownload;

                return (vat.status === 11 || vat.status === 15 || vat.status === 17 || vat.status === 20 || statusAtual.autoMessage) ? { ...messageTemplate, ...button } : messageTemplate;
            }
            return {
                id: h.idMensagem,
                title: `${new Date(h.createdAt).toLocaleDateString('pt-br')} - ${h.emissor}`,
                message: 'Mensagem customizada enviada',
                button: () => {
                    setTextMessage(h.mensagem)
                    setMessageInfo({
                        author: h.emissor,
                        date: `${new Date(h.createdAt).toLocaleDateString('pt-br')}`
                    })
                    setModalState("showMessage");
                },
                buttonText: 'Ver mensagem'
            }
        })
    }

    const [file, setFile] = useState()
    const propsFileUploader = {
        setUploadStatus: setDisable,
        accepts: modalUploadInfo.accepts,
        setFile: setFile,
    }

    const list = {
        name: 'DIVERGÊNCIAS',
        items: state.data?.tratativas.map(item => {
            const isToSend = item?.StatusGradeItem.type === "falta" && (item.idStatus === 10 || item.idStatus === 16 || item.idStatus === 19);
            return {
                isSearchPage: true,
                title: {
                    id: `Produto: ${item.produto.trim()}`,
                    description: item.desc,
                    Icons: [
                        item?.StatusGradeItem.type === "falta" && <DivergencyTag name="Falta" color="#ED8E00" />,
                        item?.StatusGradeItem.type === "sobra" && <DivergencyTag name="Sobra" color="#2596DA" />,
                    ],
                    key: item.produto.trim() + item.pedido + item.cor + item.gradeItem,
                },
                blocksInfos: {
                    block1: [
                        [{
                            key: 'Status: ',
                            value: item.Status.type,
                            highligthed: isToSend
                        }],
                    ],
                    block2: [
                        [
                            { key: 'Marca: ', value: item.descMarca },
                            { key: 'pedido: ', value: item.pedido },
                        ],
                        [
                            { key: 'Tam: ', value: item.gradeItem },
                            { key: 'Nota fiscal: ', value: item.nfSaida },
                        ],
                        [
                            {
                                key: 'Qtde: ',
                                value: item.qtde
                            },
                            { key: 'Caixa: ', value: item.caixa },
                        ],
                        [
                            { key: 'Cor: ', value: item.cor, },

                        ],

                    ],
                    block3: [
                        [
                            item.StatusGradeItem.type === "sobra" &&
                            { key: 'Manter o produto? ', value: item.querPeca ? 'Sim' : 'Não' },
                            item.StatusGradeItem.type === "falta" &&
                            { key: 'Caixa/Lacre violado? ', value: item.caixaViolada ? 'Sim' : 'Não' },
                        ],
                    ],
                },
                grid: null,
                divergency: null,
                gridReducer: null,
                componentProps: item?.newUpdate ? { additionalClassName: 'itemInfo_highlighted' } : {},
                editItemButton: isToSend && {
                    text: "Enviar arquivo",
                    Icon: <Upload />,
                    click: (e) => {
                        setModalState("upload");
                        setModalUploadInfo(item.idStatus === 16 ?
                            {
                                title: item.Status.type.toUpperCase(),
                                accepts: "image/*"
                            } :
                            {
                                title: item.Status.type.toUpperCase(),
                                accepts: "application/pdf"
                            }
                        );
                        setDivergenciaStatus(prev => ({
                            ...prev,
                            produto: item.produto,
                            gradeItem: item.gradeItem,
                            qtde: item.qtde,
                            cor: item.cor,
                            tipo: item.StatusGradeItem.type,
                            entrega: state.data?.entrega.toString(),
                            clifor: state.data?.clifor.toString(),
                            status: item.idStatus + 1
                        }))
                    },
                    componentProps: { additionalClassName: 'divergencyButtonUpdate' }
                },

            }
        },
        )
    };

    const modalContent = {
        newMessage: <SomaTextEditor setText={setTextMessage} />,
        showMessage:
            <>
                <p className='p__acompanhamento--message-info'>Enviada por: {messageInfo.author}</p>
                <p className='p__acompanhamento--message-info'>Data: {messageInfo.date}</p>
                <div className="div__acompanhamento--message" dangerouslySetInnerHTML={{ __html: textMessage }} />
            </>,
        upload: <SomaFileUploader {...propsFileUploader} />
    }

    const modalProps = {
        newMessage: {
            modalName: "ENVIAR MENSAGEM",
            hideButtons: false,
            closeName: "Voltar",
            onClose: () => setModalState("none"),
            actionName: "ENVIAR",
            open: modalState === "newMessage",
            action: () => {
                textMessage.trim() && salvarMensagem(state.data?.entrega, textMessage, state.data?.clifor, state.data?.atendente, false)
                setModalState("none")
            },
            style: { width: '80%' }
        },
        showMessage: {
            modalName: "MENSAGEM ENVIADA",
            hideButtons: true,
            onClose: () => setModalState("none"),
            open: modalState === "showMessage",
        },
        upload: {
            modalName: modalUploadInfo.title,
            open: modalState === "upload",
            actionName: "ENVIAR",
            closeName: "VOLTAR",
            actionButtonDisabled: !disable,
            action: async () => {
                let formData = new FormData();
                formData.append('file', file);
                await sendFile(state.data?.entrega, state.data?.clifor, formData, divergenciaStatus)
            },
            onClose: () => setModalState("none"),
            style: { width: "55%" }
        }
    }

    return (
        <div className='containerBoxItems'>
            <ButtonBack onClick={() => dispatch({ type: 'goBack' })} />

            <Modal {...modalProps[modalState]}>
                {modalContent[modalState]}
            </Modal>

            <div className='containerNfeBoxItems'>
                <CardBox {...propsCardBox} />

                {<PrimaryButton onClick={() => { setTextMessage(''); setModalState('newMessage') }}
                    style={{ width: '100%', margin: '10px 0' }}>
                    <SendMessage style={{ marginRight: '5px' }} />
                    ENVIAR MENSAGEM
                </PrimaryButton>}

                <Historic {...propsHistoric} />
            </div>

            <DetailsBox list={list} />

        </div>
    )
}

export default Acompanhamento