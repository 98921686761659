import React from 'react';
import { Dialog, DialogContent, Grid, Button, Typography, Box } from '@mui/material';

const Modal = (props) => {
  return (
    <Dialog data-testid="modal" maxWidth={'md'} open={props.open} onClose={props.onClose}>
      <DialogContent style={{ padding: "30px 40px 15px" }}>
        <form onSubmit={(e) => {
          e.preventDefault();
          if (props.action) {
            const a = new FormData(e.target);
            props.action(Object.fromEntries(a.entries()));
          }
        }}>
          <Typography style={{ textAlign: "center", fontWeight: "bold", fontSize: '28px' }}>
            {props.modalName.toUpperCase()}
          </Typography>
          <Box padding="15px 65px 30px">
            {props.children}
          </Box>
          {
            props.hideButtons ? null :
              <Grid item>
                <Grid container justifyContent='flex-end' direction='row' spacing={2}>
                  {props.onClose &&
                    <Grid item>
                      <Button data-testid="buttonClose" onClick={props.onClose} variant="outlined" color="primary" style={{ fontWeight: 600 }}>
                        {props.closeName || "VOLTAR"}
                      </Button>
                    </Grid>
                  }
                  {props.action &&
                    <Grid item>
                      <Button data-testid="buttonAction" type='submit' variant="contained" disabled={props.isUploading} style={{ backgroundColor: "#C6A489", color: 'white', fontWeight: 600 }}>
                        {props.actionName || "SALVAR"}
                      </Button>
                    </Grid>
                  }
                </Grid>
              </Grid>
          }
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
